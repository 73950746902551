<template>
    <div class="app-container">
      <el-row class="margin-bottom-20">
        <el-col :lg="16" :xs="16" :offset="0">
          <el-input prefix-icon="el-icon-search" size="mini" placeholder="Project Name" v-model="searchForm.query"
            v-on:change="handleSearchFilter()" clearable></el-input>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
            icon="el-icon-search">Buscar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
            icon="el-icon-delete">Limpiar</el-button>
        </el-col>
        <el-col :lg="2" :xs="8" :offset="0">
          <el-button
          @click.stop.prevent="handleExport"
          type="success"
          icon="el-icon-bottom"
          size="mini"
          >Exportar</el-button>
        </el-col>
      </el-row>
      <!-- Agrega el select de tipos de evento aquí -->
      <el-row class="margin-bottom-20">
        <el-col :lg="6" :xs="6" :offset="0">
          <el-select v-model="searchForm.projectKey" placeholder="Tipo de Proyecto" @change="handleSearchFilter">
            <el-option
              v-for="item in projects"
              :key="item.projectKey"
              :label="item.projectKey"
              :value="item.projectKey"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :lg="6" :xs="6" :offset="0">
          <el-select v-model="searchForm.active" placeholder="Está activo" @change="handleSearchFilter">
            <el-option
              v-for="item in activeStatus"
              :key="item.active ? 'true': 'false'"
              :label="item.active ? 'Sí':'No'"
              :value="item.active ? 'true': 'false'"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- Agrega el select de tipos de evento aquí -->
      <!---- Paginacion -->
      <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
      </el-row>
      <!---- Tabla de eventos ----->
      <el-table size="mini" v-loading="loading" :data="list" element-loading-text="Loading" height="600" fit border
        highlight-current-row style="width: 100%">
        <el-table-column label="Plan ID" fixed="left" align="center" width="100">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">{{ isNotEmpty(scope.row.planId) }}</div>
              <span>{{ scope.row.planId !== undefined ? scope.row.planId.toString().substr(-6) : 'N/D' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="Activo" align="center" width="100" >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.active" type="success">SÍ</el-tag>
            <el-tag v-if="!scope.row.active" type="info">NO</el-tag>
            <el-tag type="info" v-if="scope.row.active === undefined">N/D</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Monto" align="center" width="100" >
          <template slot-scope="scope">
            <span>{{ scope.row.amount !== undefined ? scope.row.amount : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Moneda" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.currency !== undefined ? scope.row.currency : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Intervalo" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.interval !== undefined ? validateInterval(scope.row.interval.toLowerCase()) : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Producto" align="center" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.product !== undefined ? scope.row.product.toString().substr(-6) : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status" align="center" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.status !== undefined ? scope.row.status : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Nombre Proyecto" align="center" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.projectName !== undefined ? scope.row.projectName : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Descripcion Proyecto" align="center" min-width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.projectDescription !== undefined ? scope.row.projectDescription : 'N/D' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Key Proyecto" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.projectKey }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Fecha de Creacion" align="center" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.created_at | formatDateTime }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
import { searchPlan, getPagePlan, fillSelectPlan, exportExcelPlans } from '@/api/stripe.js'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'plan-stripe',
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: ''
      },
      projects: [],
      activeStatus: [],
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: []
    }
  },
  mounted () {
    var idTemp = this.$route.params._id
    if (idTemp) {
      this.searchForm.userId = idTemp
    }
    this.getProps()
    this.handleFillSelectProject()
    this.handleFillSelectActiveStatus()
  },
  methods: {
    async getProps () {
      console.log(this.$route.query.product)
      const productParam = this.$route.query.product
      if (productParam !== undefined && productParam !== '' && productParam !== null) {
        this.loading = true
        await searchPlan({ query: '', product: productParam })
          .then((response) => {
            this.list = response.data.data
            this.totalPages = response.data.total
            this.list.forEach(item => (item.metadata = [item.metadata]))
          })
          .catch(this.responseCatch)
          .finally(() => (this.loading = false))
      } else {
        this.handleSearchFilter()
      }
    },
    isNotEmpty (contain) {
      return contain !== undefined || contain !== '' ? contain : 'N/D'
    },
    validateInterval (contain) {
      switch (contain) {
        case 'day':
          return 'Diaria'
        case 'week':
          return 'Semanal'
        case 'month':
          return 'Mensual'
        case 'year':
          return 'Anual'
        default:
          return contain
      }
    },
    validateStatus (contain) {
      switch (contain) {
        case 'active':
          return 'Activa'
        case 'incomplete_expired':
          return 'Expirada'
        case 'incomplete':
          return 'Incompleta'
        case 'canceled':
          return 'Cancelada'
        default:
          return contain
      }
    },
    // --- data search ----
    async handleFillSelectProject () {
      this.loading = true
      const formData = new FormData()
      formData.append('fieldSearch', 'projectKey')
      await fillSelectPlan(formData)
        .then((response) => {
          this.projects = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    // --- data search ----
    async handleFillSelectActiveStatus () {
      this.loading = true
      const formData = new FormData()
      formData.append('fieldSearch', 'active')
      await fillSelectPlan(formData)
        .then((response) => {
          this.activeStatus = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await searchPlan(this.searchForm)
        .then((response) => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.list.forEach(item => (item.metadata = [item.metadata]))
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPagePlan(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPagePlan(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleExport () {
      this.loading = true
      await exportExcelPlans(
        this.searchForm
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        const name = 'Reporte-de-Planes-Stripe-' + new Date() + '.xlsx'
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        console.log('Archivo Exportado')
      })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm = {
        query: ''
      }
      this.handleSearchFilter()
    }
  }
}
</script>

<style lang="css">

</style>
