import request from '@/utils/request'

export function searchPayment (params) {
  return request({
    url: '/api-manager/stripe/payment/search',
    method: 'post',
    params
  })
}

export function getPagePayment (page, noitems, params) {
  return request({
    url: '/api-manager/stripe/payment/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function searchSubscription (params) {
  return request({
    url: '/api-manager/stripe/subscription/search',
    method: 'post',
    params
  })
}

export function getPageSubscription (page, noitems, params) {
  return request({
    url: '/api-manager/stripe/subscription/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function searchEvent (data) {
  return request({
    url: '/api-manager/stripe/event/search',
    method: 'post',
    data
  })
}

export function getEvent (data) {
  return request({
    url: '/api-manager/stripe/event/list/attribute',
    method: 'post',
    data
  })
}

export function getPageEvent (page, noitems, params) {
  return request({
    url: '/api-manager/stripe/event/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function searchPlan (data) {
  return request({
    url: '/api-manager/stripe/plan/search',
    method: 'post',
    data
  })
}

export function getPagePlan (page, noitems, params) {
  return request({
    url: '/api-manager/stripe/plan/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function fillSelectPlan (data) {
  return request({
    url: '/api-manager/stripe/plan/fillSelect',
    method: 'post',
    data
  })
}

export function exportExcelEvents (data) {
  return request({
    url: '/api-manager/stripe/event/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function exportExcelPayments (data) {
  return request({
    url: '/api-manager/stripe/payment/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function exportExcelSubscriptions (data) {
  return request({
    url: '/api-manager/stripe/subscription/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function exportExcelPlans (data) {
  return request({
    url: '/api-manager/stripe/plan/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function filterDataPayments (data) {
  return request({
    url: '/api-manager/stripe/payment/filterData',
    method: 'post',
    data
  })
}

export function filterDataSubscriptions (data) {
  return request({
    url: '/api-manager/stripe/subscription/filterData',
    method: 'post',
    data
  })
}

export function storeStripePlan (data) {
  return request({
    url: '/api-manager/stripe/plan/create',
    method: 'post',
    data
  })
}

export function cancelStripeSubscription (subscription) {
  return request({
    url: `/api-manager/stripe/subscription/${subscription}/cancel`,
    method: 'post'
  })
}
